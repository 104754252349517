/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
}
.header {
    width: 100%;
    overflow: hidden;
    .head_content {
        height: .46rem;
        width: 100%;
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        box-shadow: 0px 5px 10px 0px #eee;
        .head_logo {
            margin-left: .05rem;
            height: .46rem;
            float: left;
            img {
                height: .4rem;
                margin-top: .03rem;
                float: left;
                max-width: 3rem;
            }
            p {
                float: left;
                margin-left: .05rem;
                strong {
                    font-size: .16rem;
                    line-height: .46rem;
                    display: block;
                }
            }
        }
        .menu {
            float: right;
            width: .46rem;
            height: .46rem;
            background: #0081d3;
            i {
                color: #fff;
                font-size: 20px;
                text-align: center;
                line-height: .46rem;
                display: block;
            }
        }
    }   
}
.app_menu {
    width: 100%;
    background: rgba(0,0,0,.5);
    position: fixed;
    height: 100%;
    top: .46rem;
    display: none;
    z-index: 99;
    ul {
        background: rgba(0,0,0,.9);
        li {
            a {
                span {
                    font-size: .14rem;
                    width: 100%;
                    height: .35rem;
                    line-height: .35rem;
                    border-bottom: 1px solid #ccc;
                    color: #fff;
                    display: block;
                    padding-left: .15rem;
                }
            }
        }
    }
}
.banner {
    width: 100%;
    padding-top: .46rem;
}
.banner_pic {
    ul {
        li {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
.banner_btn {
    width: 100%;
    height: 16px;
    position: absolute;
    left: 0;
    bottom: .1rem;
    z-index: 9;
    text-align: center;
    a {
        @include inline-block;
        @include opacity(1);
        width: .06rem;
        height: .06rem;
        border-radius: 8px;
        margin: 0 .04rem;
        background: #fff;
        transition: opacity .2s,background .2s;
        &:hover,&.in {
            @include opacity(1);
            background: #0081d3;
        }
    }
}
.banner_text {
    position: absolute;
    top: .5rem;
    left: 0;
    width: 100%;
    z-index: 9;
    padding-top: .15rem;
    span {
        display: block;
        text-align: center;
        color: #fff;
        font-size: .18rem;
    }
    strong {
        display: block;
        text-align: center;
        color: #fff;
        font-size: .2rem;
    }
    p {
        display: block;
        text-align: center;
        color: #fff;
        font-size: .1rem;
    }
    i {
        width: .6rem;
        height: 2px;
        background: #fff;
        display: block;
        margin: 0 auto;
        margin-top: 30px;
    }
}

.main {
    width: 100%;
    overflow: hidden;
}

.search {
    width: 94%;
    margin: 3%;
    margin-top: 2%; 
    height: .28rem;
    padding: .02rem;
    .texta {
        width: 85%;
        height: .24rem;
        border: none;
        border: 1px solid #ccc;
        float: left;
        font-size: .12rem;
        color: #999;
        padding-left: .08rem;
    }
    .btna {
        float: left;
        width: 15%;
        color: #fff;
        background: url(../img/sea.jpg) no-repeat left center;
        font-size: .12rem;
        text-align: center;
        height: .24rem;
        border: none;
        line-height: .24rem;
    }
}
.hot_new {
    span {
        width: .48rem;
        height: .48rem;
        padding: 10px;
        background: #fe9e22;
        font-size: .12rem;
        color: #fff;
        float: left;
        text-align: center;
        font-weight: bold;
        line-height: 140%;
    }
    .picScroll-top {
        width: 2.72rem;
        height: .48rem;
        background: #ededed;
        overflow: hidden;
        li {
            height: .48rem;
            width: 2.72rem;
            float: left;
            a {
                display: block;
                font-size: .12rem;
                color: #666;
                display: block;
                text-align: left;
                padding: 0 12px;
                line-height: .48rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
.i_box4_list {
    ul {
        li {    
            width: 100%;
            float: left;
            border-bottom: 1px dotted #eee;
            padding-bottom: .06rem;
            margin-top: .1rem;
            .fl {
                width: 1.2rem;
            }
            .imgauto {
                width: 1.2rem;
                overflow: hidden;
            }
            .fr {
                width: 1.72rem;
                a {
                    width: 100%;
                    display: block;
                    color: #000;
                    font-size: .13rem;
                    margin-bottom: .05rem;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                p {
                    width: 100%;
                    font-size: .1rem;
                    line-height: 140%;
                    display: block;
                    margin-bottom: .05rem;
                }
            }
        }
    }
}
.wrap {
    width: 3rem;
    padding: 0 .1rem;
}
a.more {
    width: 126px;
    height: 38px;
    font-size: 14px;
    color: #3f3f40;
    border: 1px solid #3f3f40;
    border-radius: 25px;
    display: block;
    text-align: center;
    line-height: 38px;
    letter-spacing: 2px;
}
.i_box1 {
    width: 100%;
    margin-top: .2rem;
    .text {
        strong {
            font-size: .18rem;
            color: #333;
            font-weight: bold;
            i {
                font-style: normal;
                font-size: .12rem;
                color: #666;
                padding-left: 8px;
            }
        }
        span {
            width: 60px;
            height: 3px;
            background: #fe9e22;
            display: block;
            margin: 10px 0;
        }
        p {
            font-size: .11rem;
            color: #343434;
            line-height: 26px;
        }
        a {
            margin: 20px 0;
        }
        img {
            width: 100%;
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
}
.box_title {
    width: 94%;
    margin: 0 3%;
    margin-top: .2rem;
    strong {
        font-size: .2rem;
        color: #333;
        font-weight: bold;
        text-align: center;
        display: block;
    }
    span {
        font-size: .12rem;
        color: #666;
        display: block;
        text-align: center;
        background: url(../img/xian.png) no-repeat center;
    }
}

.i_box2 {
    width: 100%;
    margin-top: .3rem;
}
.i_box3{
    width: 100%;
    .text {
        padding-top: .1rem;
        img {
            width: 100%;
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
        i {
            display: block;
            font-size: .1rem;
            font-style: normal;
            margin: 8px 0;
        }
        ul {
            width: 100%;
            border-top: 1px solid #eee;
            li {
                width: 100%;
                float: left;
                padding: 15px 0;
                border-bottom: 1px solid #eee;
                .fl {
                    width: 1rem;
                    float: left;
                    .imgauto {
                        width: .8rem;
                        height: .8rem;
                    }
                }
                .fr {
                    width: 2rem;
                    float: left;
                    padding-top: .1rem;
                    a {
                        display: block;
                        color: #507fc6;
                        display: block;
                        font-size: .12rem;
                    }
                    span {
                        color: #1e1e1e;
                        display: block;
                        margin: 5px 0;
                        font-size: .11rem;
                    }
                    p {
                        font-size: .11rem;
                        color: #1e1e1e;
                        display: block;
                    }
                }
            }
        }
    }
}
.i_box4 {
    padding-top: .15rem;
    width: 100%;
    .i_box4_list {
        .imgauto {
            width: 100%;
            height: 1rem;
        }
    }
}
.i_box5 {
    width: 100%;
    padding-bottom: .2rem;
}
.flag_new {
    width: 100%;
    background: #f2f2f2;
    padding: .1rem;
    margin-top: .15rem;
    .imgauto {
        width: 1rem;
        height: .8rem;
        float: left;
    }
    .text {
        width: 1.7rem;
        margin-left: .1rem;
        float: left;
        a {
            display: block;
            color: #007cd0;
            font-size: .11rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        p {
            display: block;
            font-size: .1rem;
            color: #007cd0;
            margin-top: 8px;
        }
    }
}
.i_box5_list {
    ul {
        li {    
            width: 100%;
            float: left;
            margin-top: 15px;
            .time {
                width: .6rem;
                height: .6rem;
                float: left;
                background: url(../img/time.png) no-repeat center;
                span {
                    display: block;
                    text-align: center;
                    font-size: .24rem;
                    color: #fff;
                }
                p {
                    font-size: .12rem;
                    color: #fff;
                    text-align: center;
                }
            }
            .text {
                width: 2.3rem;
                margin-left: .1rem;
                float: left;
                a {
                    font-size: .12rem;
                    color: #1e1e1e;
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                p {
                    font-size: .1rem;
                    margin-top: 8px;
                    color: #727171;
                    line-height: 22px;
                }
            }
        }
    }
}
.i_box6 {
    width: 100%;
    padding: .15rem .1rem;
    background: url(../img/hs.jpg) no-repeat top center;
    strong {
        font-size: .3rem;
        display: block;
        text-align: center;
        color: #007cd0;
        font-weight: bold;
    }
    i {
        font-size: .11rem;
        color: #007cd0;
        text-align: center;
        display: block;
        line-height: 30px;
        margin-top: 20px;
        font-style: normal;
    }
    a {
        border: 1px solid #007cd0;
        color: #007cd0;
        margin: 0 auto;
        margin-top: .2rem;
    }
    ul {
        width: 103%;
        li {
            width: .82rem;
            height: .82rem;
            float: left;
            margin: 0 13px;
            background: url(../img/yuan.png) no-repeat center;
            position: relative;
            margin-top: 20px;
            span {
                text-align: center;
                display: block;
                color: #0077ce;
                font-size: .12rem;
                padding-top: 15px;
            }
            p {
                font-size: .09rem;
                color: #3773b9;
                padding: 0 10px;
                text-align: center;
                padding-top: 8px;
                line-height: 18px;
            }
            &:first-child+li{
                margin-top: 80px;
            }
        }
    }
}
.new_list {
    width: 94%;
    margin: 0 3%;
    ul {
        li {
            width: 100%;
            float: left;
            background: url(../img/jt.png) no-repeat left center;
            padding-left: .13rem;
            height: .27rem;
            margin: .03rem 0;
            border-bottom: 1px dotted #eee;
            a {
                float: left;
                line-height: .27rem;
                font-size: .11rem;
            }
            span {
                float: right;
                line-height: .27rem;
                font-size: .1rem;
            }
        }
    }
}
.footer {
    padding-bottom: .5rem;
    p {
        font-size: .12rem;
        color: #fff;
        text-align: center;
        line-height: .24rem;
    }
}
.page_tit {
    text-align: left;
    font-size: 16px;
    line-height: 32px;
    background: #f2f2f2;
    color: #666;
    font-weight: normal;
    display: inline-block;
    width: 100%;
    height: 44px;
    margin-bottom: 10px;
    span {
        font-size: .14rem;
        color: #000;
        border-left: 4px solid #0081d3;
        line-height: 44px;
        padding-left: .05rem;
        padding-right: .05rem;
    }
    i {
        background: url(../img/rii.png) no-repeat center;
        width: 32px;
        height: 32px;
        background-size: 65%;
        float: right;
        border: none;
        transform: rotate(90deg);
        padding: 0;
        margin-top: 7px;
        margin-right: 5px;
    }
}

.page_menu {
    width: 100%;
    position: absolute;
    background: #fff;
    height: 100%;
    z-index: 9;
    display: none;
    p {
        a {
            font-size: .11rem;
            color: #0769ab;
            display: block;
            line-height: .24rem;
            text-align: left;
            padding-left: .5rem;
        }
        i {}
    }
    .v_list {
        a {
            font-size: .11rem;
            color: #0769ab;
            display: block;
            line-height: .2rem;
            text-align: left;
            padding-left: .8rem;
        }
    }
}
.closed {
    position: absolute;
    right: 30px;
    z-index: 10;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #d4d4d4;
    display: inline-block;
    text-align: center;
    line-height: 32px;
    font-size: 30px;
    color: #eee;
    z-index: 19;
}

.pro_list {
    width: 100%;
    .i_box4_list {
        .imgauto {
            width: 100%;
            height: 1rem;
        }
    }
}


.page_banner {
    li {
        img {
            width: 100%;
            height: auto;
        }
    }
}
.content_title {
    width: 3.1rem;
    margin: 0 auto;
    padding-top: .2rem;
    h3 {
        text-align: center;
        font-size: .14rem;
        color: #333;
        font-weight: normal;
    }
    span {
        color: #D9D9D9;
        font-size: .14rem;
        text-align: center;
        display: block;
        font-weight: bold;
    }
    p {
        font-size: .12rem;
        color: #999;;
        text-align: center;
        margin-top: .15rem;
    }
}
.content {
    width: 3rem;
    margin: 0 auto;
    padding: .05rem 0;
    img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }
    p {
        font-size: .11rem;
        margin-bottom: .1rem;
        line-height: 1.7em;
    }
}
.pro_menu {
    background: #1abde8;
    width: 100%;
    margin-top: -.05rem;
    height: 1.19rem;
    padding-top: .24rem;
    ul {
        padding-left: .2rem;;
        li {
            padding: 0 .08rem;
            height: .3rem;
            background: #2fc1ec;
            float: left;
            margin: 5px 6px 0 0;
            a {
                font-size: .11rem;
                color: #fff;
                display: block;
                line-height: .3rem;
                text-align: center;
            }
        }
    }
}
.pages {
    width: 100%;
    text-align: center;
    margin: .3rem 0;
    a {
        width: .22rem;
        height: .22rem;
        background: #fff;
        border: 1px solid #0769ab;
        color: #0769ab;
        text-align: center;
        font-size: .11rem;
        line-height: .22rem;
        margin: 0 .03rem;
        @include inline-block();
        &.active {
            background: #0769ab;
            color: #fff;
        }
    }
}
#marquee2 {
    margin-top: .1rem;
    ul {
        height: 2.2rem;
        overflow: hidden;
        li {
            float: left;
            padding: 0;
            width: 1.7rem;
            img {
                width: 1.5rem;
                height: 2.06rem;
                display: block;
                margin: 0 auto;
            }
        }
    }
}
.pro_content {
    width: 3.1rem;
    margin: 0 auto;
    padding-bottom: .3rem;
}
.content_tit {
    width: 100%;
    h3 {
        font-size: .18rem;
        text-align: center;
        color: #0b89dd;
        line-height: .33rem;
    }
    p {
        text-align: center;
        width: 100%;
    }
    span {
        font-size: .11rem;
        color: #999;
        text-align: center;
        display: inline-block;
        line-height: .23rem;
        margin: 0 5px;
    }
}
.content_des {
    width: 100%;
    background: #F5F5F5;
    border: 1px solid #E5E5E5;
    padding: .05rem;
    margin-bottom: .2rem;
    margin-top: .1rem;
    p {
        color: #868686;
        font-size: .12rem;
        line-height: .2rem;
    }
}
.content_img {
    width: 100%;
    overflow: hidden;
    padding-bottom: .1rem;
    margin-bottom: .1rem;
    border-bottom: 1px solid #eee;
    img {
        width: 100%;
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }
}
.show_pages {
    width: 100%;
    padding-left: .1rem;
    padding-bottom: .1rem;
    padding-top: .05rem;
    p {
        width: 100%;
        font-size: .11rem;
        color: #666;
        line-height: .2rem;
        a {
            font-size: .11rem;
            color: #666;
        }
    }
}
.news_list {
    width: 3.1rem;
    margin: 0 auto;
    padding-top: .2rem;
    ul {
        li {
            width: 100%;
            height: .28rem;
            line-height: .28rem;
            float: left;
            border-bottom: 1px solid #e2e2e2;
            .fl {
                a {
                    i {
                        font-style: normal;
                        float: left;
                        font-size: .12rem;
                        margin-right: .05rem;
                        color: #0b89dd;
                        font-weight: bold;
                    }
                    span {
                        float: left;
                        font-size: .12rem;
                        width: 1.7rem;
                        @include ellipsis ($clamp: 1);
                    }
                }
            }
            .fr {
                p {
                    font-size: .11rem;
                    color: #acacac;
                }
            }
        }
    }
}
.show_guest {
    width: 3rem;
    margin: 0 auto;
    padding: .2rem 0;
    p {
        width: 100%;
        margin: .07rem 0;
        span {
            padding: .1rem 0;
            line-height: 1.5em;
            display: block;
        }
        input {
            width: 3rem;
            border: none;
            border: 1px solid #ccc;
            height: .4rem;
            text-indent: 5px;
            font-size: .12rem;
            border-radius: .03rem;
        }
        textarea {
            width: 3rem;
            height: .7rem;
            border: 1px solid #ccc;
        }
        .btn {
            background: #0B89DD;
            color: #fff;
        }
    }
}
.foot_nav {
    width: 100%;
    background: #f8f8f8;
    position: fixed;
    bottom: 0;
    left: 0;
    overflow: hidden;
    height: .46rem;
    ul {
        li {
            width: 25%;
            float: left;
            padding-top: .27rem;
            border-right: 1px solid #d8d8d8;
            a {
                display: block;
                i{
                    font-size: .24rem;
                    padding: .03rem 0;
                    display: block;
                    text-align: center;
                }
                span {
                    display: block;
                    text-align: center;
                    font-size: .1rem;
                }
            }
            &:first-child {
                background: url(../img/ic1.png) no-repeat center .03rem;
                background-size: 25%;
                i {
                    color: #f96900;
                }
                span {
                    color: #f96900;
                }
            }
            &:first-child+li {
                background: url(../img/ic2.png) no-repeat center .03rem;
                background-size: 25%;
            }
            &:first-child+li+li {
                background: url(../img/ic3.png) no-repeat center .03rem;
                background-size: 25%;
            }
            &:first-child+li+li+li {
                background: url(../img/ic4.png) no-repeat center .03rem;
                background-size: 25%;
            }
        }
    }
}
.hs_cont {
    width: 100%;
    padding: 0 .1rem;
    .text {
        width: 100%;
        padding-bottom: .15rem;
        margin-bottom: .15rem;
        border-bottom: 1px solid #eee;
        span {
            display: block;
            font-size: .11rem;
            color: #0081d3;
        }
        p {
            font-size: .1rem;
            line-height: .21rem;
        }
    }
}
.parnet_menu {
    ul {
        li {
            width: 25%;
            float: left;
            height: .36rem;
            a {
                text-align: center;
                font-size: .14rem;
                line-height: .36rem;
                display: block;
                background: #f2f2f2;
            }
        }
    }
}



/****************************** media ******************************/
